import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../animations/fadeInOut';
import { App } from '../apps/models/app.model';
import { ActivatedRoute } from '@angular/router';
import { AppsSandbox } from '../apps/apps.sandbox';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: fadeInOut,
})
export class HomeComponent implements OnInit {
  app: App;
  appUrl: string;

  constructor(sandbox: AppsSandbox) {
    this.app = sandbox.getAppById('finalysis');
    this.appUrl = '/apps/' + this.app.appId;
  }

  ngOnInit(): void {}
}
