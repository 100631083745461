<section class="text-white py-5 bg-darker" [@fadeInOut]>
  <div class="container py-5">
    <div class="row p-2 my-2 justify-content-center">
      <div class="col-lg-3 bg-dark d-flex p-2 rounded shadow">
        <img class="m-auto" height="192px" src="/assets/images/apps/finalysis/logo.png" />
      </div>
      <div class="col-lg-6">
        <div class="m-auto my-4 overflow-visible">
          <p class="text-secondary">FEATURED</p>
          <a class="h1 text-primary" routerLink="{{ appUrl }}" style="text-decoration: none;">Finalysis
          </a>
          <p>
            A powerful finance management app, now in the palm of your hand. Manage expenses, loans and analyse your
            daily spendings
            to gain insights into your finances.
          </p>
          <a href="https://play.google.com/store/apps/details?id=com.ghostware.expensetracker" target="_blank"
            class="btn btn-primary btn-lg mx-2"><span class="iconify" data-icon="mdi:google-play"
              data-inline="false"></span>
            Get it</a>
          <a routerLink="/apps/" class="btn btn-outline-secondary btn-lg mx-2">More Apps
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="py-5 bg-dark">
  <div class="container py-5 m-auto text-white">
    <p class="h1">Get your own application created</p>
    <div class="row">
      <div class="col-lg-4 p-2 text-center">
        <div class="bg-dark-less mb-2 rounded shadow p-2 h-100">
          <p class="h1 p-2 m-0">Mobile</p>
          <hr class="border border-dark" />
          <p class="m-2">
            Need your own cross-platform mobile application that is secure,
            robust and also well-designed? You are in the right place!
          </p>
          <a routerLink="request" class="btn btn-primary mb-2">Get a quote</a>
        </div>
      </div>
      <div class="col-lg-4 p-2 text-center">
        <div class="bg-dark-less mb-2 rounded shadow p-2 h-100">
          <p class="h1 p-2 m-0">Web</p>
          <hr class="border border-dark" />
          <p class="m-2">
            We develop and design responsive and captivating websites that
            provide the best experience to the users.
          </p>
          <a routerLink="request" class="btn btn-primary mb-2">Get a quote</a>
        </div>
      </div>
      <div class="col-lg-4 p-2 text-center">
        <div class="bg-dark-less mb-2 rounded shadow p-2 h-100">
          <p class="h1 p-2 m-0">Desktop</p>
          <hr class="border border-dark" />
          <p class="m-2">
            You can also have your own desktop applications designed with
            beautiful interface and competitive performance.
          </p>
          <a routerLink="request" class="btn btn-primary mb-2">Get a quote</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="py-5 bg-darker">
  <div class="container py-5 m-auto text-white">
    <p class="h1 text-right">A bit about us</p>
    <div class="row text-center">
      <div class="col-lg-4 row mx-auto my-2">
        <div class="col-lg-12 bg-dark p-2 shadow rounded">
          <p class="h1">The Team</p>
          <hr class="border border-dark" />
          <p>
            A small team of highly experienced and passionate individuals with
            only one goal, customer satisfaction. Our team will make sure all
            your demands are fulfilled and the delivered product is beyond your
            expectations.
          </p>
          <p>
            We will fulfill your demands with dedication and try our best to
            make it as convenient as possible for you.
          </p>
        </div>
      </div>
      <div class="col-lg-4 row mx-auto my-2">
        <div class="col-lg-12 bg-dark p-2 shadow rounded">
          <p class="h1">Experience</p>
          <hr class="border border-dark" />
          <p>
            Our experience spans out to different types of projects including
            website development and design, android development and desktop
            application development.
          </p>
          <p>
            We already have a lot of projects that have been put out in the
            market. You can take a look at all of them from the
            <a routerLink="/apps">Applications</a> page.
          </p>
        </div>
      </div>
      <div class="col-lg-4 row mx-auto my-2">
        <div class="col-lg-12 bg-dark p-2 shadow rounded">
          <p class="h1">Customer Satisfaction</p>
          <hr class="border border-dark" />
          <p>
            Customer satisfaction is our foremost priority and we will make sure
            to put in all our dedication and make your project look as amazing
            as you imagined it.
          </p>
          <p>
            If you want an application developed for your needs, don't hesitate
            to <a routerLink="/request">Get in Touch</a>. We will make sure you
            are happy with this choice!
          </p>
        </div>
      </div>
    </div>
  </div>
</section>