<footer class="bg-dark">
  <div class="container p-5 m-auto text-white">
    <div class="row">
      <div class="col-lg-4 p-2 text-center h2 mx-auto my-auto">
        <p class="h1">Get in touch</p>
        <a href="https://www.facebook.com/Ghostware-109786514152164" class="h2 btn text-primary"><span class="iconify"
            data-icon="mdi-facebook" data-inline="false"></span></a>
        <a href="https://twitter.com/Ghostware_twt" class="h2 btn text-info" aria-hidden="true"><span class="iconify"
            data-icon="mdi-twitter" data-inline="false"></span></a>
        <a href="https://www.instagram.com/ghostware_ig/" class="h2 btn text-danger" aria-hidden="true"><span
            class="iconify" data-icon="mdi-instagram" data-inline="false"></span></a>
        <a href="https://www.linkedin.com/company/ghostware" class="h2 btn text-secondary" aria-hidden="true"><span
            class="iconify" data-icon="mdi-linkedin" data-inline="false"></span></a>
        <a href="mailto:hello@ghostware.xyz" class="h2 btn text-white" aria-hidden="true"><span class="iconify"
            data-icon="mdi-email" data-inline="false"></span></a>
      </div>
      <div class="col-lg-4 p-2 text-center mx-auto my-auto">
        <img src="assets/images/logo.svg" height="128" />
      </div>
      <div class="col-lg-4 p-2 text-center mx-auto my-auto">
        <p>Copyright &copy; 2025 - Ghostware</p>
        <p class="text-secondary">
          All rights reserved. No part of this publication may be reproduced,
          distributed, or transmitted in any form or by any means, including
          photocopying, recording, or other electronic or mechanical methods,
          without the prior written permission of the publisher, except in the
          case of brief quotations embodied in critical reviews and certain
          other noncommercial uses permitted by copyright law.
        </p>
      </div>
    </div>
  </div>
</footer>