import { Injectable } from '@angular/core';
import { App } from '../models/app.model';
import { appData } from '../data/apps';

@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  constructor() {}

  getAllApps(): Array<App> {
    return appData;
  }

  getAppById(id: string): App {
    return this.getAppByParam(id, 'appId');
  }

  getAppsByType(type: string): App[] {
    return appData.filter((a) => a.type === type);
  }

  private getAppByParam(value: string, param: string): App {
    let app: App;
    appData.some((a) => {
      if (a[param] === value) {
        app = a;
        return true;
      }
    });
    return app;
  }
}
