import { Injectable } from '@angular/core';
import { App } from './models/app.model';
import { AppDataService } from './services/app-data.service';

@Injectable({
  providedIn: 'root',
})
export class AppsSandbox {
  constructor(private appDataService: AppDataService) {}
  getAllApps(): App[] {
    return this.appDataService.getAllApps();
  }

  getAppById(id: string): App {
    return this.appDataService.getAppById(id);
  }

  getAppsByType(type: string): App[] {
    return this.appDataService.getAppsByType(type);
  }
}
